function tabActive() {
    $('.infinite-tab-nav-item.active').each(function() {
        var theID = $(this).attr('href'),
            tabItem = $(theID);
        tabItem.siblings().hide();
        tabItem.fadeIn();
    })
}

$(document).on('click', '.infinite-tab-nav-item', function() {
    $(this).siblings().removeClass('active');
    $(this).addClass('active');
    tabActive();
});

$(window).on('load', function() {
    tabActive()
});