function openModalOnHash() {
    if(window.location.hash) {
      var hash = window.location.hash.substring(1);
      if (hash == 'login' || hash == 'register') {
          $('#login-page-modal, #login-page').attr('data-form', hash);
          
            $('#'+hash+'-form').siblings().hide();
            $('#'+hash+'-form').fadeIn();
          $('#user-login-register').fadeIn();
          $('body').addClass('modal-opened');
      }
    }
  }

$(document).ready(function() {
    openModalOnHash()
});

$(document).on('click', '.loginregister-btn', function() {
    var hashID = $(this).data('id');
    $('#login-page-modal').attr('data-form', hashID);
    $('#'+hashID+'-form').siblings().hide();
    $('#'+hashID+'-form').fadeIn();
    $('#user-login-register').fadeIn();
    $('body').addClass('modal-opened');
});

$(document).on('click', '.close-login-modal', function() {
    $('#user-login-register').fadeOut();
    $('body').removeClass('modal-opened');
});

$(document).on('click', '.lc-nav-item', function() {
    var dataID = $(this).data('id');

    $('#login-page, #login-page-modal').attr('data-form', dataID);
    $('#'+dataID+'-form').siblings().hide();
    $('#'+dataID+'-form').fadeIn();
})