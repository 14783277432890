$('.event-slider').slick({
    dots: false,
    infinite: false,
    speed: 300,
    prevArrow: '<i class="icon icon-chevron-right prev"></i>',
    nextArrow: '<i class="icon icon-chevron-left next"></i>',
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
        breakpoint: 1024,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
        }
        },
        {
        breakpoint: 768,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
        },
        {
        breakpoint: 540,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
        }
    ]
});
