$(document).on('click', '#mobile-menu-trigger', function(e) {
    $('#mobile-menu-drawer').addClass('menu-opened');

    e.stopPropagation();
})
$(document).on('click', '.close-menu, #mobile-menu a', function(e) {
    $('#mobile-menu-drawer').removeClass('menu-opened');

    e.stopPropagation();
})
