$(document).on('click', '.user-nav-trigger', function(e) {
    $(this).parent().toggleClass('menu-opened');

    e.stopPropagation();
})

$(document).mouseup(function(e)
{
    var container = $("#user-area");
    if (!container.is(e.target) && container.has(e.target).length === 0) 
    {
        container.removeClass('menu-opened');
    }
});