$('.about-image-slider').slick({
    dots: false,
    infinite: false,
    speed: 300,
    prevArrow: '<i class="icon icon-chevron-right prev"></i>',
    nextArrow: '<i class="icon icon-chevron-left next"></i>',
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        
        {
        breakpoint: 600,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
        },
        {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
        }
    ]
});
